<template>
  <div>
    <a-page-header
      :title="$route.query.id ? '修改快讯' : '添加快讯'"
      @back="() => $router.go(-1)"
    />
    <a-row>
      <a-col :span="16">
        <a-form :label-col="{ span: 3 }" :wrapper-col="{ span: 20 }">
          <a-form-item label="标题">
            <a-input v-model="addFromData.title" />
          </a-form-item>
          <a-form-item label="简介">
            <a-textarea
              :auto-size="{ minRows: 3, maxRows: 5 }"
              v-model="addFromData.desc"
            />
          </a-form-item>

          <a-form-item label="内容">
            <UE
              :sentContent="addFromData.content"
              @getUeContent="getUeContent"
            ></UE>
          </a-form-item>
        </a-form>
      </a-col>
      <a-col :span="8">
        <a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
          <a-form-item label="状态">
            <a-radio-group v-model="addFromData.status">
              <a-radio :value="1">
                启用
              </a-radio>
              <a-radio :value="0">
                禁用
              </a-radio>
            </a-radio-group>
          </a-form-item>

          <a-form-item label="选择企业">
            <CompanySelect
              style="width:200px"
              @CompanySelect="CompanySelect"
              :serchKeyword="addFromData.company_name"
              :selectId="addFromData.company_id"
            ></CompanySelect>
          </a-form-item>

          <a-form-item label="缩略图">
            <a-upload
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="false"
              :action="uploadImage"
              :before-upload="beforeUpload"
              @change="handleChange"
            >
              <img
                v-if="imageUrl"
                :src="imageUrl"
                style="display:block;max-width:100px;max-height:100px"
              />
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
              </div>
            </a-upload>
          </a-form-item>
          <a-button type="primary" style="margin-left:150px" @click="handleOk">
            保存
          </a-button>
        </a-form>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import UE from "@/components/UE/UE.vue";
import { uploadImage, bulletinDataSave, bulletinDetail } from "@/apis/index";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";

export default {
  data() {
    return {
      locale,

      addFromData: {
        id: "",
        title: "",
        company_id: undefined,
        company_name: "",
        status: 1,
        img_id: "",
        desc: "",
        content: "",
      },
      uploadImage,
      loading: false,
      imageUrl: "",
      previewImage: "",
    };
  },
  components: {
    UE,
  },
  computed: {},
  watch: {},
  created() {
    if (this.$route.query.id) {
      bulletinDetail({ id: this.$route.query.id }).then((res) => {
        console.log(res);
        if (res.code == 1) {
          this.addFromData = { ...res.data };
          this.imageUrl = res.data.img_url;
          this.addFromData.company_name = res.data.company.company_name;
        }
      });
    }
  },
  methods: {
    CompanySelect(val) {
      this.addFromData.company_id = val;
    },
    getUeContent(val) {
      this.addFromData.content = val;
    },
    handleOk() {
      bulletinDataSave(this.addFromData).then((res) => {
        console.log(res);
        if (res.code == 1) {
          this.$router.go(-1);
          this.$message.success("保存成功");
        }
      });
    },

    handleChange(info) {
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        this.imageUrl = info.file.response.data.url;
        this.addFromData.img_id = info.file.response.data.id;
        this.loading = false;
      }
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("You can only upload JPG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("Image must smaller than 2MB!");
      }
      return isJpgOrPng && isLt2M;
    },
  },
};
</script>

<style lang="less" scoped></style>
